import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import Profile from './Profile';
import LearningManagementSystem from './LearningManagementSystem';
import NAAC from './NAAC';
import Reports from './Reports';
import Parct1 from './pages/pract1';
import AddUser from './Crud/Add';
import EditUser from './Crud/Edit';
import DeleteUser from './Crud/Delete';
import ExportUsers from './Crud/Export';
import Viewcourse from './pages/Viewcourse';
import Viewcourse1 from './pages/Viewcourse1';
import Login from './pages/Login';
import Dashmcas11 from './pages/Dashmcas11';

import Dashmncas11 from './pages/Dashmncas11';
import Dashmncas11admin from './pages/Dashmncas11admin';
import Dashmncas12 from './pages/Dashmncas12';
import Dashmncas12admin from './pages/Dashmncas12admin';

import Dashmncas22 from './pages/Dashmncas22';
import Dashmncas22admin from './pages/Dashmncas22admin';
import Dashmncas23 from './pages/Dashmncas23';
import Dashmncas23admin from './pages/Dashmncas23admin';
import Dashmncas241 from './pages/Dashmncas241';
import Dashmncas241admin from './pages/Dashmncas241admin';
import Dashmncas242 from './pages/Dashmncas242';
import Dashmncas242admin from './pages/Dashmncas242admin';
import Dashmncas243 from './pages/Dashmncas243';
import Dashmncas243admin from './pages/Dashmncas243admin';
import Dashmncas251 from './pages/Dashmncas251';
import Dashmncas251admin from './pages/Dashmncas251admin';
import Dashmncas252 from './pages/Dashmncas252';
import Dashmncas252admin from './pages/Dashmncas252admin';
import Dashmncas253 from './pages/Dashmncas253';
import Dashmncas253admin from './pages/Dashmncas253admin';
import Dashmncas26 from './pages/Dashmncas26';
import Dashmncas26admin from './pages/Dashmncas26admin';

import Dashmprojects from './pages/Dashmprojects';
import Dashmprojectsadmin from './pages/Dashmprojectsadmin';

import Dashmpublications from './pages/Dashmpublications';
import Dashmpublicationsadmin from './pages/Dashmpublicationsadmin';
import Dashmpatents from './pages/Dashmpatents';
import Dashmpatentsadmin from './pages/Dashmpatentsadmin';
import Dashmteacherfellow from './pages/Dashmteacherfellow';
import Dashmteacherfellowadmin from './pages/Dashmteacherfellowadmin';
import Dashmconsultancy from './pages/Dashmconsultancy';
import Dashmconsultancyadmin from './pages/Dashmconsultancyadmin';
import Dashmphdguide from './pages/Dashmphdguide';
import Dashmphdguideadmin from './pages/Dashmphdguideadmin';
import Dashmseminar from './pages/Dashmseminar';
import Dashmseminaradmin from './pages/Dashmseminaradmin';
import Dashmbook from './pages/Dashmbook';
import Dashmbookadmin from './pages/Dashmbookadmin';

import Dashmexamschedule from './pages/Dashmexamschedule';
import Dashmexamscheduleadmin from './pages/Dashmexamscheduleadmin';
import Dashmexamroom from './pages/Dashmexamroom';
import Dashmexamroomadmin from './pages/Dashmexamroomadmin';

import Loginstud from './pages/Loginstud';

import Dashmmprograms from './pages/Dashmmprograms';
import Dashmmprogramsadmin from './pages/Dashmmprogramsadmin';
import Dashmmcourses from './pages/Dashmmcourses';
import Dashmmcoursesadmin from './pages/Dashmmcoursesadmin';
import Dashmmstudents from './pages/Dashmmstudents';
import Dashmmstudentsadmin from './pages/Dashmmstudentsadmin';
import Dashmexamtimetable from './pages/Dashmexamtimetable';
import Dashmexamtimetableadmin from './pages/Dashmexamtimetableadmin';

import Dashmmfaccourses from './pages/Dashmmfaccourses';
import Dashmmfaccoursesadmin from './pages/Dashmmfaccoursesadmin';
import Dashmmfaccoursesatt from './pages/Dashmmfaccoursesatt';
import Dashmmfaccoursesattadmin from './pages/Dashmmfaccoursesattadmin';

import Dashmmattcalc from './pages/Dashmmattcalc';
import Dashmmattcalcadmin from './pages/Dashmmattcalcadmin';

import Dashmmcolevels from './pages/Dashmmcolevels';
import Dashmmcolevelsadmin from './pages/Dashmmcolevelsadmin';

import Dashmmcolevelscalc from './pages/Dashmmcolevelscalc';

import Dashmmcourseslist from './pages/Dashmmcourseslist';
import Dashmmcourseslistadmin from './pages/Dashmmcourseslistadmin';

import Dashmmstudents1 from './pages/Dashmmstudents1';
import Dashmmstudents1admin from './pages/Dashmmstudents1admin';

import Dashmclassenr1 from './pages/Dashmclassenr1';
import Dashmclassenr1admin from './pages/Dashmclassenr1admin';

import Dashmclassenr1stud from './pages/Dashmclassenr1stud';

import Dashmmsyllabus from './pages/Dashmmsyllabus';
import Dashmmsyllabusstud from './pages/Dashmmsyllabusstud';
import Dashmmsyllabusadmin from './pages/Dashmmsyllabusadmin';

import Dashmmassignments from './pages/Dashmmassignments';
import Dashmmassignmentsadmin from './pages/Dashmmassignmentsadmin';
import Dashmmanouncements from './pages/Dashmmanouncements';
import Dashmmanouncementsadmin from './pages/Dashmmanouncementsadmin';
import Dashmmcourseco from './pages/Dashmmcourseco';
import Dashmmcoursecoadmin from './pages/Dashmmcoursecoadmin';
import Dashmmcalendar from './pages/Dashmmcalendar';
import Dashmmcalendaradmin from './pages/Dashmmcalendaradmin';
import Dashmmcoursematerial from './pages/Dashmmcoursematerial';
import Dashmmcoursematerialadmin from './pages/Dashmmcoursematerialadmin';

import Dashmmannouncementsstud from './pages/Dashmmannouncementsstud';
import Dashmmassignmentsstud from './pages/Dashmmassignmentsstud';
import Dashmmcalendarstud from './pages/Dashmmcalendarstud';
import Dashmmcoursematerialstud from './pages/Dashmmcoursematerialstud';
import Dashmmcoursecostud from './pages/Dashmmcoursecostud';

import Dashmmassignsubmit from './pages/Dashmmassignsubmit';
import Dashmmassignsubmitadmin from './pages/Dashmmassignsubmitadmin';
import Dashmmdiscussion from './pages/Dashmmdiscussion';
import Dashmmdiscussionadmin from './pages/Dashmmdiscussionadmin';

import Dashmmassignsubmitfac from './pages/Dashmmassignsubmitfac';

import Dashmquotanew from './pages/Dashmquotanew';
import Dashmquotanewadmin from './pages/Dashmquotanewadmin';

// import Resistor from './pages/Resistor';
// import Bubblesort from './pages/BubbleSort';
// import Firstsimulator from './pages/FirstSimulator';
// import SecondSimulator from './pages/SecondSimulator';

import Dashmclassnew from './pages/Dashmclassnew';
import Dashmclassnewadmin from './pages/Dashmclassnewadmin';
import Dashmattendancenew from './pages/Dashmattendancenew';
import Dashmattendancenewadmin from './pages/Dashmattendancenewadmin';

import SubHalfAdder1 from './pages/SubHalfAdder1';
import BasicLogicGateExpFirst from './pages/BasicLogicGateExpFirst';
import BasicLogicGateExpSecond from './pages/BasicLogicGateExpSecond';
import HalfSubtractor from './pages/HalfSubtractor';
import FullSubtractor from './pages/FullSubtractor';
import TwoBitAdder from './pages/TwoBitAdder';

import Dashmattccode from './pages/Dashmattccode';
import Dashmattpcode from './pages/Dashmattpcode';
import Dashmclassnewc from './pages/Dashmclassnewc';

import Dashmclassnewstud from './pages/Dashmclassnewstud';
import FourBitAdderSubtractor from './pages/FourBitAdderSubtractor';

import Dashmstudawardsnew from './pages/Dashmstudawardsnew';
import Dashmstudawardsnewadmin from './pages/Dashmstudawardsnewadmin';
import Dashmscholnew from './pages/Dashmscholnew';
import Dashmscholnewadmin from './pages/Dashmscholnewadmin';
import Dashmscholnewstud from './pages/Dashmscholnewstud';
import Dashmstudawardsnewstud from './pages/Dashmstudawardsnewstud';

import Dashmslideshow from './pages/Dashmslideshow';
import Dashmslideshowadmin from './pages/Dashmslideshowadmin';

import Dashmeventsnew1 from './pages/Dashmeventsnew1';
import Dashmeventsnew1admin from './pages/Dashmeventsnew1admin';
import Dashmpolicy from './pages/Dashmpolicy';

import Dashmqualitative from './pages/Dashmqualitative';
























function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/viewcourse1" element={<Viewcourse1 />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/learning-management-system" element={<LearningManagementSystem />} />
        <Route path="/naac" element={<NAAC />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/Parct1" element={<Parct1 />} />
        <Route path="/add" element={<AddUser />} />
        <Route path="/edit/:id" element={<EditUser />} />
        <Route path="/delete/:id" element={<DeleteUser />} />
        {/* <Route path="/users" element={<Users />} /> */}
        <Route path="/export" element={<ExportUsers />} />
        <Route path="/dashmcas11" element={<Dashmcas11 />} />
        <Route path="/dashmncas11" element={<Dashmncas11 />} />
        <Route path="/dashmncas11admin" element={<Dashmncas11admin />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/dashmncas12" element={<Dashmncas12 />} />
        <Route path="/dashmncas12admin" element={<Dashmncas12admin />} />

        <Route path="/dashmqualitative" element={<Dashmqualitative />} />
        <Route path="/dashmpolicy" element={<Dashmpolicy />} />
        <Route path="/dashmeventsnew1" element={<Dashmeventsnew1 />} />
        <Route path="/dashmeventsnew1admin" element={<Dashmeventsnew1admin />} />


        <Route path="/dashmslideshow" element={<Dashmslideshow />} />
        <Route path="/dashmslideshowadmin" element={<Dashmslideshowadmin />} />

        
        <Route path="/dashmscholnew" element={<Dashmscholnew />} />
        <Route path="/dashmscholnewadmin" element={<Dashmscholnewadmin />} />
        <Route path="/dashmstudawardsnew" element={<Dashmstudawardsnew />} />
        <Route path="/dashmstudawardsnewadmin" element={<Dashmstudawardsnewadmin />} />
        <Route path="/dashmscholnewstud" element={<Dashmscholnewstud />} />
        <Route path="/dashmstudawardsnewstud" element={<Dashmstudawardsnewstud />} />



        <Route path="/fourbitaddersubtractor" element={<FourBitAdderSubtractor />} />
        <Route path="/dashmclassnewstud" element={<Dashmclassnewstud />} />
        <Route path="/dashmclassnewc" element={<Dashmclassnewc />} />
        <Route path="/dashmattccode" element={<Dashmattccode />} />
        <Route path="/dashmattpcode" element={<Dashmattpcode />} />

        <Route path="/subhalfadder1" element={<SubHalfAdder1 />} />
        <Route path="/basiclogicgateexpfirst" element={<BasicLogicGateExpFirst />} />
        <Route path="/basiclogicgateexpsecond" element={<BasicLogicGateExpSecond />} />
        <Route path="/fullsubtractor" element={<FullSubtractor />} />
        <Route path="/halfsubtractor" element={<HalfSubtractor />} />
        <Route path="/twobitadder" element={<TwoBitAdder />} />

        <Route path="/dashmclassnew" element={<Dashmclassnew />} />
<Route path="/dashmclassnewadmin" element={<Dashmclassnewadmin />} />
<Route path="/dashmattendancenew" element={<Dashmattendancenew />} />
<Route path="/dashmattendancenewadmin" element={<Dashmattendancenewadmin />} />


        {/* <Route path="/resistor" element={<Resistor />} />
        <Route path="/bubblesort" element={<Bubblesort />} />
        <Route path="/firstsimulator" element={<Firstsimulator />} />
        <Route path="/secondsimulator" element={<SecondSimulator />} /> */}


        <Route path="/dashmquotanew" element={<Dashmquotanew />} />
<Route path="/dashmquotanewadmin" element={<Dashmquotanewadmin />} />


        <Route path="/dashmmassignsubmitfac" element={<Dashmmassignsubmitfac />} />

        <Route path="/dashmmassignsubmit" element={<Dashmmassignsubmit />} />
<Route path="/dashmmassignsubmitadmin" element={<Dashmmassignsubmitadmin />} />
<Route path="/dashmmdiscussion" element={<Dashmmdiscussion />} />
<Route path="/dashmmdiscussionadmin" element={<Dashmmdiscussionadmin />} />


        <Route path="/dashmmcoursecostud" element={<Dashmmcoursecostud />} />
        <Route path="/dashmmcoursematerialstud" element={<Dashmmcoursematerialstud />} />
        <Route path="/dashmmcalendarstud" element={<Dashmmcalendarstud />} />
        <Route path="/dashmmassignmentsstud" element={<Dashmmassignmentsstud />} />
        <Route path="/dashmmanouncementsstud" element={<Dashmmannouncementsstud />} />

        <Route path="/dashmmassignments" element={<Dashmmassignments />} />
<Route path="/dashmmassignmentsadmin" element={<Dashmmassignmentsadmin />} />
<Route path="/dashmmanouncements" element={<Dashmmanouncements />} />
<Route path="/dashmmanouncementsadmin" element={<Dashmmanouncementsadmin />} />
<Route path="/dashmmcourseco" element={<Dashmmcourseco />} />
<Route path="/dashmmcoursecoadmin" element={<Dashmmcoursecoadmin />} />
<Route path="/dashmmcalendar" element={<Dashmmcalendar />} />
<Route path="/dashmmcalendaradmin" element={<Dashmmcalendaradmin />} />
<Route path="/dashmmcoursematerial" element={<Dashmmcoursematerial />} />
<Route path="/dashmmcoursematerialadmin" element={<Dashmmcoursematerialadmin />} />


        <Route path="/dashmmsyllabus" element={<Dashmmsyllabus />} />
        
<Route path="/dashmmsyllabusadmin" element={<Dashmmsyllabusadmin />} />

<Route path="/dashmmsyllabusstud" element={<Dashmmsyllabusstud />} />

        <Route path="/dashmclassenr1" element={<Dashmclassenr1 />} />
<Route path="/dashmclassenr1admin" element={<Dashmclassenr1admin />} />
<Route path="/dashmclassenr1stud" element={<Dashmclassenr1stud />} />



        <Route path="/dashmmstudents1" element={<Dashmmstudents1 />} />
<Route path="/dashmmstudents1admin" element={<Dashmmstudents1admin />} />


        <Route path="/dashmmcourseslist" element={<Dashmmcourseslist />} />
<Route path="/dashmmcourseslistadmin" element={<Dashmmcourseslistadmin />} />


        <Route path="/dashmmcolevelscalc" element={<Dashmmcolevelscalc />} />

        <Route path="/dashmmcolevels" element={<Dashmmcolevels />} />
<Route path="/dashmmcolevelsadmin" element={<Dashmmcolevelsadmin />} />


        <Route path="/dashmmattcalc" element={<Dashmmattcalc />} />
<Route path="/dashmmattcalcadmin" element={<Dashmmattcalcadmin />} />


        <Route path="/dashmmfaccourses" element={<Dashmmfaccourses />} />
<Route path="/dashmmfaccoursesadmin" element={<Dashmmfaccoursesadmin />} />
<Route path="/dashmmfaccoursesatt" element={<Dashmmfaccoursesatt />} />
<Route path="/dashmmfaccoursesattadmin" element={<Dashmmfaccoursesattadmin />} />



        <Route path="/dashmmprograms" element={<Dashmmprograms />} />
<Route path="/dashmmprogramsadmin" element={<Dashmmprogramsadmin />} />
<Route path="/dashmmcourses" element={<Dashmmcourses />} />
<Route path="/dashmmcoursesadmin" element={<Dashmmcoursesadmin />} />
<Route path="/dashmmstudents" element={<Dashmmstudents />} />
<Route path="/dashmmstudentsadmin" element={<Dashmmstudentsadmin />} />
<Route path="/dashmexamtimetable" element={<Dashmexamtimetable />} />
<Route path="/dashmexamtimetableadmin" element={<Dashmexamtimetableadmin />} />


        
        <Route path="/loginstud" element={<Loginstud />} />
        <Route path="/dashmexamschedule" element={<Dashmexamschedule />} />
<Route path="/dashmexamscheduleadmin" element={<Dashmexamscheduleadmin />} />
<Route path="/dashmexamroom" element={<Dashmexamroom />} />
<Route path="/dashmexamroomadmin" element={<Dashmexamroomadmin />} />



        <Route path="/dashmpublications" element={<Dashmpublications />} />
<Route path="/dashmpublicationsadmin" element={<Dashmpublicationsadmin />} />
<Route path="/dashmpatents" element={<Dashmpatents />} />
<Route path="/dashmpatentsadmin" element={<Dashmpatentsadmin />} />
<Route path="/dashmteacherfellow" element={<Dashmteacherfellow />} />
<Route path="/dashmteacherfellowadmin" element={<Dashmteacherfellowadmin />} />
<Route path="/dashmconsultancy" element={<Dashmconsultancy />} />
<Route path="/dashmconsultancyadmin" element={<Dashmconsultancyadmin />} />
<Route path="/dashmphdguide" element={<Dashmphdguide />} />
<Route path="/dashmphdguideadmin" element={<Dashmphdguideadmin />} />
<Route path="/dashmseminar" element={<Dashmseminar />} />
<Route path="/dashmseminaradmin" element={<Dashmseminaradmin />} />
<Route path="/dashmbook" element={<Dashmbook />} />
<Route path="/dashmbookadmin" element={<Dashmbookadmin />} />



        <Route path="/dashmprojects" element={<Dashmprojects />} />
<Route path="/dashmprojectsadmin" element={<Dashmprojectsadmin />} />



        <Route path="/dashmncas22" element={<Dashmncas22 />} />
<Route path="/dashmncas22admin" element={<Dashmncas22admin />} />
<Route path="/dashmncas241" element={<Dashmncas241 />} />
<Route path="/dashmncas241admin" element={<Dashmncas241admin />} />
<Route path="/dashmncas23" element={<Dashmncas23 />} />
<Route path="/dashmncas23admin" element={<Dashmncas23admin />} />
<Route path="/dashmncas242" element={<Dashmncas242 />} />
<Route path="/dashmncas242admin" element={<Dashmncas242admin />} />
<Route path="/dashmncas243" element={<Dashmncas243 />} />
<Route path="/dashmncas243admin" element={<Dashmncas243admin />} />
<Route path="/dashmncas251" element={<Dashmncas251 />} />
<Route path="/dashmncas251admin" element={<Dashmncas251admin />} />
<Route path="/dashmncas252" element={<Dashmncas252 />} />
<Route path="/dashmncas252admin" element={<Dashmncas252admin />} />
<Route path="/dashmncas253" element={<Dashmncas253 />} />
<Route path="/dashmncas253admin" element={<Dashmncas253admin />} />
<Route path="/dashmncas26" element={<Dashmncas26 />} />
<Route path="/dashmncas26admin" element={<Dashmncas26admin />} />



        
      </Routes>
    </Router>
  );
}

export default App;
